import React, { useContext, useState, useEffect, useMemo } from "react";
import "./Splash.css";
import logo from "./img/punkhunt.png";
import { WalletContext } from "./WalletContext";

function Splash() {
  const { account, connectWallet } = useContext(WalletContext);

  const steps = useMemo(
    () => [
      { id: 1, label: "Step 1 Mint Ducks", link: "#duckmint" },
      { id: 2, label: "Step 2 Buy Zappers", link: "#zappmint" },
      { id: 3, label: "Step 3 Sh00t Ducks", link: "#burn" },
      { id: 4, label: "Leaderboard", link: "#stats" },
    ],
    []
  );

  const [selectedStep, setSelectedStep] = useState(steps[0].id);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowDown") {
        event.preventDefault();
        setSelectedStep((prev) => (prev < steps.length ? prev + 1 : prev));
      } else if (event.key === "ArrowUp") {
        event.preventDefault();
        setSelectedStep((prev) => (prev > 1 ? prev - 1 : prev));
      } else if (event.key === "Enter") {
        const step = steps.find((step) => step.id === selectedStep);
        if (step) {
          window.location.href = step.link;
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [selectedStep, steps]);

  const goToDuckMint = (event) => {
    event.preventDefault();
    event.stopPropagation(); // Prevent event propagation
    console.log("#duckmint");
    window.location.href = "#duckmint";
  };

  return (
    <div className="splash p-2" id="splash">
      <div className="container h-100">
        <div className="row h-100">
          <div className="col-12 text-center align-self-center">
            <img src={logo} className="img-fluid w-50" alt="Base Hunt logo" />
          </div>
        </div>
      </div>

      <div className="container h-100">
        <div className="row h-100">
          <div className="col-12 text-center align-self-center">
            <h2 className="pt-4 pb-4">A Based Onchain OE PvP NFT game</h2>
          </div>
        </div>
      </div>

      <div className="container h-100">
        <div className="row h-100 justify-content-center">
          <div className="col-12 text-center align-self-center">
            {steps.map((step) => (
              <label key={step.id} className="step-label">
                <input
                  type="radio"
                  className="nes-radio is-dark"
                  name="answer-dark"
                  checked={selectedStep === step.id}
                  readOnly
                />
                <span>
                  <a
                    href={step.link}
                    style={{ color: "#ff650b" }}
                    onClick={(event) => event.stopPropagation()}
                  >
                    {step.label}
                  </a>
                </span>
                <br />
              </label>
            ))}
          </div>
        </div>
      </div>

      <div className="container h-100">
        <div className="row h-100">
          <div className="col-12 text-center align-self-center">
            <h2 className="pt-4 pb-4" style={{ color: "#97E500" }}>
              Top Score = ETH Reward!
            </h2>
            <div className="developed-by">
              <span>Developed by:</span>
              <a
                className="underlinelink p-2"
                href="https://twitter.com/AspynPalatnick"
                target="_blank"
                rel="noopener noreferrer"
              >
                @Aspyn
              </a>
              <a
                className="underlinelink p-2"
                href="https://twitter.com/cartyisme"
                target="_blank"
                rel="noopener noreferrer"
              >
                @cartyisme
              </a>
              <a
                className="underlinelink p-2"
                href="https://twitter.com/lza_menace"
                target="_blank"
                rel="noopener noreferrer"
              >
                @lza_menace
              </a>
              <a
                className="underlinelink p-2"
                href="https://warpcast.com/cc0mfer"
                target="_blank"
                rel="noopener noreferrer"
              >
                @cc0mfer
              </a>
            </div>
            <div className="pt-4">©2024</div>
          </div>
        </div>
      </div>

      <button className="button-transparent" onClick={goToDuckMint}>
        ↓
      </button>
    </div>
  );
}

export default Splash;
