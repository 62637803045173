// index.js
import "nes.css/css/nes.min.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./fonts/Pixel_NES.otf";
import 'bootstrap/dist/js/bootstrap.bundle.min';
import React, { useContext } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Nav from './Nav';
import Splash from './Splash';
import Ducks from './Ducks';
import Zapp from './Zapp';
import Ded from './Ded';
import Stats from './Stats';
import Faq from './Faq';
import Terms from './Terms';
// import Trollbox from './Trollbox';
import { WalletProvider, WalletContext } from './WalletContext';
import { NotificationProvider, NotificationContext } from './NotificationContext';
import reportWebVitals from './reportWebVitals';

const App = () => {
  const { account } = useContext(WalletContext);

  return (
    <React.StrictMode>
      <Nav />
      <Splash />
      <Ducks />
      <Zapp />
      <Ded />
      <Stats />
      <Faq />
      <Terms />
    </React.StrictMode>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <WalletProvider>
    <NotificationProvider>
      <App />
    </NotificationProvider>
  </WalletProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
