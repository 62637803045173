/* global BigInt */
import React, { useContext, useState, useEffect } from 'react';
import './Ded.css';
import pepe from './img/pepe.png';
import { getContractData, sendTransaction } from './contractInteraction';
import { WalletContext } from './WalletContext';

function Ded() {
  const { account } = useContext(WalletContext);
  const [shootAmount, setShootAmount] = useState(5);
  const [totalDed, setTotalDed] = useState('0');
  const [maxDed, setMaxDed] = useState('1'); // Initialize with '1' to avoid division by zero

  useEffect(() => {
    const fetchTotalDed = async () => {
      try {
        const total = await getContractData('Main', 'ducksRekt');
        const max = await getContractData('Main', 'duckMaxSurvivorCount');
        setTotalDed(total ? total.toString() : '0'); // Ensure a default value if the call fails
        setMaxDed(max ? max.toString() : '1');     // Ensure a default value if the call fails
      } catch (error) {
        console.error('Error fetching total ded or max ded:', error);
      }
    };

    fetchTotalDed();
  }, []);

  const handleShootDucks = async () => {
    try {
      await sendTransaction('Main', 'sendZappers', shootAmount);
      const total = await getContractData('Main', 'ducksRekt');
      setTotalDed(total ? total.toString() : '0');
    } catch (error) {
      console.error('Error shooting ducks:', error);
    }
  };

  const goToStatsScreen = () => {
    window.location.href = '#stats';
  };

  // Convert strings to BigInt for calculations
  const totalDedBigInt = BigInt(totalDed);
  const maxDedBigInt = BigInt(maxDed);
  const progressValue = Number(totalDedBigInt) / Number(maxDedBigInt) * 100;

  return (
    <div className="ded-section" id="burn">
      <div className="container-fluid h-100">
        <div className="row h-100 pt-5">
          <div className="col-12 text-center align-self-center">
            <h1 className="p-2" style={{ color: '#000000' }}>
              Shoot{' '}
              <input
                type="text"
                style={{ width: '2.5em' }}
                id="inline_field"
                className="nes-input default_select"
                placeholder="5"
                value={shootAmount}
                onChange={(e) => setShootAmount(e.target.value)}
              />{' '}
              Ducks!
            </h1>
            <button type="button" className="nes-btn" onClick={handleShootDucks} disabled={!account}>
              Shoot Ducks
            </button>
            <p>TX fee</p>
            <progress className="w-75 nes-progress" value={progressValue} max="100" style={{ color: '#000000' }}></progress>
            <h1 className="p-2" style={{ color: '#000000' }}>{totalDed}/{maxDed} Ded Ducks!</h1>

            <p>The game ends when one duck remains.</p> 

            <img src={pepe} className="img-fluid hunter" alt="Hunter" />
          </div>
        </div>
      </div>

      <button className="button-transparent" onClick={goToStatsScreen}>↓</button>
    </div>
  );
}

export default Ded;
