import React from 'react';
import "./Faq.css";

function App() {
  return (
    <div className="faq pt-2 pb-4">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-12 text-center">
            <div className="faqs-section" id="faqs"></div>
            <h1 className="pt-4 pb-4">Frequently Asked Questions</h1>
          </div>
        </div>
      </div>

      <div className="container-fluid pt-4">
        <div className="row justify-content-center">
          <div className="col-10">
            <h2>Contract:</h2>
            <a className="underlinelink" href="https://etherscan.io/address/0x0000...DEAD" style={{ color: '#ff650b' }}>
              0x0000...DEAD
            </a>
          </div>
        </div>
      </div>

      <div className="container-fluid pt-4">
        <div className="row justify-content-center">
          <div className="col-10">
            <a className="underlinelink" href="https://opensea.io" style={{ color: '#3BC3FD' }}>
              Base Hunt on OpenSea
            </a>
          </div>
        </div>
      </div>

      <div className="container-fluid pt-4">
        <div className="row justify-content-center">
          <div className="col-10">
            <h2>Wen mint?</h2>
            <br />
            <p>Mint is TBA.</p>
          </div>
        </div>
      </div>

      <div className="container-fluid pt-4">
        <div className="row justify-content-center">
          <div className="col-10">
            <h2>What is a PvP NFT Game?</h2>
            <br />
            <p>
              "PvP" stands for "Player versus Player." Base Hunt is an analog
              NFT game, inspired by{" "}
              <a
                className="underlinelink"
                href="https://en.wikipedia.org/wiki/Duck_Hunt"
                target="_blank"
                rel="noopener noreferrer"
              >
                Nintendo's Duck Hunt
              </a>
              , where players compete to be the last duck standing.
            </p>
            <p>
              The objective is to shoot other players' NFT ducks with{" "}
              <a
                className="underlinelink"
                href="#zappmint"
              >
                zappers
              </a>
              , ruining their NFT. The game ends when one duck remains. The last
              address holding a live duck and the address that kills the most
              ducks each win 25% of the total contract funds.
            </p>
            <p>
              It's like "
              <a
                className="underlinelink"
                href="https://en.wikipedia.org/wiki/King_of_the_hill_(game)"
                target="_blank"
                rel="noopener noreferrer"
              >
                king-of-the-hill
              </a>
              " with a non-fungible twist.
            </p>
          </div>
        </div>
      </div>

      <div className="container-fluid pt-4">
        <div className="row justify-content-center">
          <div className="col-10">
            <h2>So it's not a collectible?</h2>
            <br />
            <p>
              No, Base Hunt is anti-collectible. It's a digital experience, a
              game, not static digital art.
            </p>
          </div>
        </div>
      </div>

      <div className="container-fluid pt-4">
        <div className="row justify-content-center">
          <div className="col-10">
            <h2>Discord?</h2>
            <br />
            <p>
              No Discord. If you'd like to reach out to us on Discord, feel free
              to join the{" "}
              <a
                className="underlinelink"
                href="https://discord.gg/b3YYQdbmnx"
                target="_blank"
                rel="noopener noreferrer"
              >
                Art101 Discord
              </a>
              . You can find @lza and @cartyisme there.
            </p>
          </div>
        </div>
      </div>

      <div className="container-fluid pt-4">
        <div className="row justify-content-center">
          <div className="col-10">
            <h2>Okay, so I mint a duck now what?</h2>
            <br />
            <p>
              That's up to you! You can hold your duck and pray it survives, or
              join in the fray and eliminate fellow punks from the competition
              by minting and burning zappers to shoot other collectors' NFT
              ducks.
            </p>
          </div>
        </div>
      </div>

      <div className="container-fluid pt-4">
        <div className="row justify-content-center">
          <div className="col-10">
            <h2>Is there a maximum amount of ducks I can mint?</h2>
            <br />
            <p>
              Yep! A wallet can only mint 50 NFT ducks max. If you need more,
              you can buy them on{" "}
              <a className="underlinelink" href="https://opensea.io" target="_blank" rel="noopener noreferrer">
                OpenSea
              </a>
              .
            </p>{" "}
            <p>The more ducks you own, the more likely you win!</p>
          </div>
        </div>
      </div>

      <div className="container-fluid pt-4">
        <div className="row justify-content-center">
          <div className="col-10">
            <h2>Is there a maximum amount of zappers I can mint?</h2>
            <br />
            <p>
              Nope! You can mint and burn as many zappers as you like. The more
              ducks you shoot the more likely you climb the leaderboard. When
              the game ends, the address that kills the most ducks wins 25% of
              the total contract funds!
            </p>
          </div>
        </div>
      </div>

      <div className="container-fluid pt-4">
        <div className="row justify-content-center">
          <div className="col-10">
            <h2>When can I start shooting ducks?</h2>
            <br />
            <p>
              When the ducks have completed their 69 hour and 42 minute mint,
              zappers can begin shooting ducks.
            </p>
          </div>
        </div>
      </div>

      <div className="container-fluid pt-4">
        <div className="row justify-content-center">
          <div className="col-10">
            <h2>
              Cool, I minted zappers, how do I choose which ducks to shoot?
            </h2>
            <br />
            <p>
              You don't, when a zapper is burned a random duck is shot. The
              Token IDs are selected via RNG in the name of fairness. Aim
              carefully, you might miss.
            </p>
          </div>
        </div>
      </div>

      <div className="container-fluid pt-4">
        <div className="row justify-content-center">
          <div className="col-10">
            <h2>Can I see the ducks I shoot?</h2>
            <br />
            <p>
              Of course! Our UI will let you know which ducks you killed each
              time you fire off your zappers. You can also view your kills on
              etherscan.
            </p>
          </div>
        </div>
      </div>

      <div className="container-fluid pt-4">
        <div className="row justify-content-center">
          <div className="col-10">
            <h2>How do I know if my duck is dead?</h2>
            <br />
            <p>
              Refresh the metadata and Pepe will let you know. When a duck gets
              shot, the token's image and traits are flipped to reflect its
              rekt-ness.
            </p>
          </div>
        </div>
      </div>

      <div className="container-fluid pt-4">
        <div className="row justify-content-center">
          <div className="col-10">
            <h2>
              So if I kill the most ducks, or own the last duck standing, I get
              paid!?
            </h2>
            <br />
            <p>
              That's right, the address with the most kills and the address
              holding the last duck each receive 25% of the contract funds. Keep
              tabs on who's winning via the{" "}
              <a
                className="underlinelink"
                href="#stats"
              >
                leaderboard
              </a>
              .
            </p>
          </div>
        </div>
      </div>

      <div className="container-fluid pt-4">
        <div className="row justify-content-center">
          <div className="col-10">
            <h2>Royalty?</h2>
            <br />
            <p>Z-E-R-O</p>
          </div>
        </div>
      </div>

      <div className="container-fluid pt-4">
        <div className="row justify-content-center">
          <div className="col-10">
            <h2>Filter?</h2>
            <br />
            <p>Never.</p>
          </div>
        </div>
      </div>

      <div className="container-fluid pt-4">
        <div className="row justify-content-center">
          <div className="col-10">
            <h2>Roadmap?</h2>
            <br />
            <p>
              No roadmap, Base Hunt is a one-off digital experience, a game. We
              are not building a brand. We are experimenting and having fun. If
              you'd like to see what we get up to next, follow us on Twitter.
            </p>
          </div>
        </div>
      </div>

      <div className="container-fluid pt-4">
        <div className="row justify-content-center">
          <div className="col-10">
            <h2>Is this for real!?</h2>
            <br />
            <p>Yes, we are for real.</p>{" "}
            <p>
              Base Hunt is an adaptation of our previous drop,{" "}
              <a
                className="underlinelink"
                href="https://twitter.com/unaboomernft"
                target="_blank"
                rel="noopener noreferrer"
              >
                Unaboomer
              </a>
              . Consider it our MVP, or proof of concept. Base Hunt is an Open
              Edition variation on the proven{" "}
              <a
                className="underlinelink"
                href="https://twitter.com/unaboomernft"
                target="_blank"
                rel="noopener noreferrer"
              >
                Unaboomer
              </a>{" "}
              mechanics.
            </p>{" "}
            <p>Unaboomer minters will receive a free NFT duck airdrop.</p>
          </div>
        </div>
      </div>

      <div className="container-fluid pt-4">
        <div className="row justify-content-center">
          <div className="col-10">
            <h2>And who are you?</h2>
            <br />
            <p>
              We're doxxed on the{" "}
              <a
                className="underlinelink"
                href="#splash"
              >
                title page
              </a>
              , where our Twitters are linked. We created{" "}
              <a
                className="underlinelink"
                href="https://art101.io"
                target="_blank"
                rel="noopener noreferrer"
              >
                Art101
              </a>
              , minted 30,000+ Free NFTs over{" "}
              <a
                className="underlinelink"
                href="https://opensea.io/art101.eth/created"
                target="_blank"
                rel="noopener noreferrer"
              >
                10 generative collections
              </a>
              , and have reputations to keep.
            </p>{" "}
            <p>We've been innovating in this space for years. No rug here.</p>{" "}
            <p>
              Winners will be paid within 3 days of the contest ending. Our
              contract is verified, and activity can be audited on-chain.
            </p>
            <br />
          </div>
        </div>
      </div>

    </div>
  );
}

export default App;
