import React, { useContext, useState, useEffect } from 'react';
import { parseEther } from 'ethers'; // Import parseEther directly
import './Ducks.css';
import duck from './img/duck.png';
import fence from './img/fence.png';
import { getContractData, sendTransaction } from './contractInteraction';
import { WalletContext } from './WalletContext';

function Ducks() {
  const { account } = useContext(WalletContext);
  const [mintAmount, setMintAmount] = useState(5);
  const [totalMinted, setTotalMinted] = useState(0);
  const [walletMinted, setWalletMinted] = useState(0); // New state to track ducks minted by the connected wallet

  useEffect(() => {
    const fetchTotalMinted = async () => {
      try {
        const result = await getContractData('Duck', 'minted'); // Call the minted method instead of totalSupply
        console.log('Total minted:', result);
        result ? setTotalMinted(Number(result)) : setTotalMinted(0); // Ensure result is converted to number
      } catch (error) {
        console.error('Error fetching total minted:', error);
      }
    };

    const fetchWalletMinted = async () => {
      try {
        if (account) {
          const result = await getContractData('Duck', 'tokensMintedByWallet', account); // Pass account directly
          console.log('Wallet minted:', result);
          setWalletMinted(Number(result)); // Ensure result is converted to number
        }
      } catch (error) {
        console.error('Error fetching wallet minted:', error);
      }
    };

    fetchTotalMinted();
    fetchWalletMinted();
  }, [account]);

  const handleMintDucks = async (event) => {
    event.preventDefault();
    event.stopPropagation(); // Prevent event propagation
    console.log("Mint Ducks button clicked");
    try {
      const value = parseEther((0.005 * mintAmount).toString());
      const receipt = await sendTransaction('Main', 'mintDucks', mintAmount, { value });
      console.log('Transaction receipt:', receipt);
      const result = await getContractData('Duck', 'minted'); // Call the minted method instead of totalSupply
      setTotalMinted(Number(result)); // Ensure result is converted to number
      const walletResult = await getContractData('Duck', 'tokensMintedByWallet', account); // Pass account directly
      setWalletMinted(Number(walletResult)); // Update the wallet's minted amount after minting
    } catch (error) {
      console.error('Error minting ducks:', error);
    }
  };

  const goToZappMint = (event) => {
    event.preventDefault();
    event.stopPropagation(); // Prevent event propagation
    console.log("Go to Zapp Mint button clicked");
    window.location.href = '#zappmint';
  };

  return (
    <div className="duckmint-section" id="duckmint" onClick={(e) => e.stopPropagation()}>
      <div className="container-fluid h-100">
        <div className="row h-100">
          <div className="col-12 text-center align-self-center">
            <div className="d-none d-lg-block col-6 offset-6 p-2">
              <img src={duck} style={{ height: '12em' }} className="img-fluid" alt="Duck" />
            </div>
            <div className="d-lg-none col-6 offset-6 p-2">
              <img src={duck} style={{ height: '4em' }} className="img-fluid" alt="Duck" />
            </div>
            <h1 className="p-4" style={{ color: '#000000' }}>
              Mint{' '}
              <input
                type="text"
                style={{ width: '2.5em' }}
                id="inline_field"
                className="nes-input default_select"
                placeholder="5"
                value={mintAmount}
                onChange={(e) => setMintAmount(Number(e.target.value))} // Convert value to number
                onClick={(e) => e.stopPropagation()}
              />{' '}
              Ducks!
            </h1>
            <button type="button" className="nes-btn" onClick={handleMintDucks} disabled={!account}>
              Mint Ducks
            </button>
            <p>0.01E</p>
            <h1 className="" style={{ color: '#000000' }}>69:42:00 TBA</h1>
            <h1 className="pb-2" style={{ color: '#000000' }}>{totalMinted} Minted!</h1>
            {account && (
              <h2 className="pb-2" style={{ color: '#000000' }}>{walletMinted} Ducks Minted by You!</h2>
            )}
            <p>
              <a href="#stats" className="underlinelink" style={{ color: '#ea6126' }}>
                last duck standing
              </a>{' '}
              wins 25% of the contract funds!
            </p>
            <button className="button-transparent" onClick={goToZappMint}>↓</button>
          </div>
        </div>
      </div>
      <img src={fence} className="img-fluid fence" alt="Fence" />
    </div>
  );
}

export default Ducks;
