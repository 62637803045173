import { ethers } from 'ethers';
import { contracts } from './contractConfig';

const getSigner = async () => {
  const provider = new ethers.BrowserProvider(window.ethereum);
  await provider.send("eth_requestAccounts", []);
  return provider.getSigner();
};

const getContractInstance = async (contractName, signerOrProvider) => {
  const contractConfig = contracts[contractName];
  if (!contractConfig) {
    throw new Error(`Contract ${contractName} is not configured`);
  }
  const contract = new ethers.Contract(contractConfig.address, contractConfig.abi, signerOrProvider);
  console.log(`Contract instance for ${contractName} with${signerOrProvider instanceof ethers.AbstractSigner ? '' : 'out'} signer:`, contract);
  return contract;
};

export const getContractData = async (contractName, method, ...args) => {
  try {
    const provider = new ethers.BrowserProvider(window.ethereum);
    const contract = await getContractInstance(contractName, provider); // Use provider
    console.log(`Calling method ${method} on contract ${contractName} with args:`, args);
    const data = await contract[method](...args);
    return data;
  } catch (error) {
    console.error(`Error fetching data from ${contractName} contract:`, error);
  }
};

export const sendTransaction = async (contractName, method, ...args) => {
  try {
    const signer = await getSigner();
    const fromAddress = await signer.getAddress();
    const contract = await getContractInstance(contractName, signer); // Use signer
    const txOptions = args.length > 0 && typeof args[args.length - 1] === 'object' ? args.pop() : {};
    console.log(`Sending transaction from address ${fromAddress} to method ${method} on contract ${contractName} with args:`, args, txOptions);
    const transaction = await contract[method](...args, { ...txOptions, from: fromAddress });
    const receipt = await transaction.wait();
    return receipt;
  } catch (error) {
    console.error(`Error sending transaction to ${contractName} contract:`, error);
    throw error;
  }
};
