import React, { createContext, useState, useEffect } from 'react';
import { ethers } from 'ethers';

export const WalletContext = createContext();

export const WalletProvider = ({ children }) => {
  const [account, setAccount] = useState(null);
  const [loading, setLoading] = useState(true);

  const connectWallet = async () => {
    if (window.ethereum) {
      try {
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner();
        const address = await signer.getAddress();
        setAccount(address);

        // Check and add/switch network if necessary
        const network = await provider.getNetwork();
        const chainId = Number(network.chainId);
        console.log('Detected chainId:', chainId);
        const targetChainIdHex = '0x' + Number(84532).toString(16);
        console.log('Target chainId hex:', targetChainIdHex);

        if (chainId !== 84532) {
          try {
            console.log('Attempting to switch to chain ID:', targetChainIdHex);
            await window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: targetChainIdHex }] // 84532 in hex
            });
          } catch (switchError) {
            console.error('Switch Error:', switchError);
            if (switchError.code === 4902) {
              try {
                console.log('Attempting to add and switch to chain ID:', targetChainIdHex);
                await window.ethereum.request({
                  method: 'wallet_addEthereumChain',
                  params: [{
                    chainId: targetChainIdHex,
                    chainName: 'Base Sepolia',
                    rpcUrls: ['https://sepolia.base.org'],
                    nativeCurrency: {
                      name: 'Base Sepolia ETH',
                      symbol: 'ETH',
                      decimals: 18
                    },
                    blockExplorerUrls: ['https://sepolia.etherscan.io']
                  }]
                });
                // Try switching again after adding
                console.log('Switching again to chain ID:', targetChainIdHex);
                await window.ethereum.request({
                  method: 'wallet_switchEthereumChain',
                  params: [{ chainId: targetChainIdHex }]
                });
              } catch (addError) {
                console.error('Failed to add the network:', addError);
              }
            } else {
              console.error('Failed to switch the network:', switchError);
            }
          }
        }
      } catch (error) {
        console.error('Error connecting to wallet:', error);
      }
    } else {
      alert('Please install MetaMask to use this feature.');
    }
  };

  useEffect(() => {
    connectWallet();
    setLoading(false);
  }, []);

  if (loading) {
    return <div>Loading...</div>; // You can replace this with a more sophisticated loading indicator
  }

  return (
    <WalletContext.Provider value={{ account, setAccount, connectWallet }}>
      {children}
    </WalletContext.Provider>
  );
};
