import React from 'react';
import './Terms.css';

function App() {
  return (
    <div className="terms pt-1" id="terms">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-12 text-center">
            <h1 className="pt-2">Terms of Service</h1>
            <p>
              <i className="nes-icon is-small heart"></i>&#160;fuck the police&#160;
              <i className="nes-icon is-small heart"></i>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
