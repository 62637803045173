import React, { useContext, useState, useEffect } from 'react';
import './Zapp.css';
import { getContractData, sendTransaction } from './contractInteraction';
import { WalletContext } from './WalletContext';
import { parseEther } from 'ethers';

function ZappMint() {
  const { account } = useContext(WalletContext);
  const [mintAmount, setMintAmount] = useState(5);
  const [totalMinted, setTotalMinted] = useState(0);

  useEffect(() => {
    const fetchTotalMinted = async () => {
      try {
        const result = await getContractData('Zapper', 'totalSupply');
        console.log('Total minted:', result);
        setTotalMinted(Number(result)); // Ensure result is converted to number
      } catch (error) {
        console.error('Error fetching total minted:', error);
      }
    };

    fetchTotalMinted();
  }, []);

  const handleMintZappers = async () => {
    try {
      const value = parseEther((0.0025 * mintAmount).toString());
      await sendTransaction('Main', 'mintZappers', mintAmount, { value });
      const result = await getContractData('Zapper', 'totalSupply');
      setTotalMinted(Number(result)); // Ensure result is converted to number
    } catch (error) {
      console.error('Error minting zappers:', error);
    }
  };

  const goToBurnScreen = () => {
    window.location.href = '#burn';
  };

  return (
    <div className="zappmint-section" id="zappmint">
      <div className="content-container">
        <h1 className="p-2" style={{ color: '#000000' }}>
          Mint{' '}
          <input
            type="text"
            style={{ width: '2.5em' }}
            id="inline_field"
            className="nes-input default_select"
            placeholder="5"
            value={mintAmount}
            onChange={(e) => setMintAmount(Number(e.target.value))} // Convert value to number
          />{' '}
          Zappers!
        </h1>
        <button type="button" className="nes-btn" onClick={handleMintZappers} disabled={!account}>
          Mint Zappers
        </button>
        <p>0.0025E</p>
        <h1 className="pb-2" style={{ color: '#000000' }}>{totalMinted} Minted!</h1>

        <p>Burn Zappers below to shoot ducks! Aim Carefully, you might miss...</p>
        <p>The <a href="#stats" style={{ color: '#ff650b' }} className="underlinelink">Top Shot</a> Wins 25% of the Contract Funds Once the Game is Complete!</p>
        <p>When a duck gets shot the token's image and traits are flipped to reflect its rekt-ness.</p>
        <p>Wanna know more!? <a href="#faqs" style={{ color: '#ff650b' }}>Read the FAQ</a>.</p>
        <button className="button-transparent" onClick={goToBurnScreen}>↓</button>
      </div>
    </div>
  );
}

export default ZappMint;
