import React, { useContext, useState } from 'react';
import { ethers } from 'ethers';
import { WalletContext } from './WalletContext';
import { NotificationContext } from './NotificationContext';
import './Nav.css';

function Nav() {
  const { account, setAccount } = useContext(WalletContext);
  const { addNotification } = useContext(NotificationContext);
  const [hover, setHover] = useState(false);

  console.log('Nav component rendered. Account:', account);

  const connectWallet = async () => {
    console.log('connectWallet function called');
    if (window.ethereum) {
      try {
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner();
        const address = await signer.getAddress();
        setAccount(address);
        addNotification('Wallet connected successfully!', 'success');

        // Check and add/switch network if necessary
        const network = await provider.getNetwork();
        const chainId = Number(network.chainId);
        console.log('Detected chainId:', chainId);
        const targetChainIdHex = '0x' + Number(84532).toString(16);
        console.log('Target chainId hex:', targetChainIdHex);

        if (chainId !== 84532) {
          try {
            console.log('Attempting to switch to chain ID:', targetChainIdHex);
            await window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: targetChainIdHex }] // 84532 in hex
            });
          } catch (switchError) {
            console.error('Switch Error:', switchError);
            if (switchError.code === 4902) {
              try {
                console.log('Attempting to add and switch to chain ID:', targetChainIdHex);
                await window.ethereum.request({
                  method: 'wallet_addEthereumChain',
                  params: [{
                    chainId: targetChainIdHex,
                    chainName: 'Base Sepolia',
                    rpcUrls: ['https://sepolia.base.org'],
                    nativeCurrency: {
                      name: 'Base Sepolia ETH',
                      symbol: 'ETH',
                      decimals: 18
                    },
                    blockExplorerUrls: ['https://sepolia.etherscan.io']
                  }]
                });
                // Try switching again after adding
                console.log('Switching again to chain ID:', targetChainIdHex);
                await window.ethereum.request({
                  method: 'wallet_switchEthereumChain',
                  params: [{ chainId: targetChainIdHex }]
                });
              } catch (addError) {
                console.error('Failed to add the network:', addError);
                alert('Failed to add the network. Please try again or add it manually in your wallet.');
                addNotification('Failed to add the network. Please try again or add it manually in your wallet.', 'error');
              }
            } else {
              console.error('Failed to switch the network:', switchError);
              alert('Failed to switch the network. Please try again or switch it manually in your wallet.');
              addNotification('Failed to switch the network. Please try again or switch it manually in your wallet.', 'error');
            }
          }
        }
      } catch (error) {
        console.error('Error connecting to wallet:', error);
        addNotification('Error connecting to wallet. Please try again.', 'error');
      }
    } else {
      alert('Please install MetaMask to use this feature.');
      addNotification('Please install MetaMask to use this feature.', 'warning');
    }
  };

  const disconnectWallet = () => {
    console.log('disconnectWallet function called');
    setAccount(null);
    localStorage.removeItem('WEB3_CONNECT_CACHED_PROVIDER');
    addNotification('Wallet disconnected successfully!', 'info');
  };

  return (
    <nav>
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-6 justify-content-start d-flex">
          <a href="#faqs" className="underlinelink" style={{ color: '#ea6126' }}>FAQ</a>
          </div>
          <div className="col-6 d-flex justify-content-end">
            {account ? (
              <span 
                className="nes-btn is-success"
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                onClick={disconnectWallet}
                style={{ cursor: 'pointer' }}
              >
                {hover ? 'Disconnect' : `${account.substring(0, 6)}...${account.substring(account.length - 4)}`}
              </span>
            ) : (
              <button type="button" className="nes-btn" onClick={connectWallet}>Connect</button>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Nav;
