import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Stats.css';
import { getContractData } from './contractInteraction';

function App() {
  const [liveDucks, setLiveDucks] = useState(0);
  const [deadDucks, setDeadDucks] = useState(0);
  const [liveShots, setLiveShots] = useState(0);
  const [shotsFired, setShotsFired] = useState(0);
  const [topHolders, setTopHolders] = useState([]);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const ducksRektResult = await getContractData('Main', 'ducksRekt');
        const ducksMintedResult = await getContractData('Main', 'ducksMinted');
        const zappersBurnedResult = await getContractData('Main', 'zappersBurned');
        const zappersMintedResult = await getContractData('Main', 'zappersMinted');

        const liveDucksResult = Number(ducksMintedResult) - Number(ducksRektResult);
        setLiveDucks(liveDucksResult);
        setDeadDucks(Number(ducksRektResult));
        setLiveShots(Number(zappersMintedResult) - Number(zappersBurnedResult));
        setShotsFired(Number(zappersBurnedResult));
      } catch (error) {
        console.error('Error fetching stats:', error);
      }
    };

    const fetchTopHolders = async () => {
      try {
        const response = await axios.get('http://localhost:8787/opensea-top-holders');
        const sortedHolders = response.data;
        setTopHolders(sortedHolders);
      } catch (error) {
        console.error('Error fetching top holders:', error);
      }
    };    

    fetchStats();
    fetchTopHolders();
  }, []);

  const obfuscateAddress = (address) => {
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
  };

  const goToFaq = () => {
    window.location.href = '#faqs';
  };

  return (
    <div className="splash pt-2" id="stats">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-12 text-center">
            <h1 className="pt-4">Leaderboard</h1>
          </div>
        </div>
      </div>

      <div className="container-fluid pt-4">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="d-flex text-center">
              <div className="col-6 text-left">
                <h2 className="section-title" style={{ color: '#97E500' }}>Stats</h2>
              </div>
              <div className="col-6 text-right">
                <h2 className="section-title" style={{ color: '#97E500' }}>Count</h2>
              </div>
            </div>
            <hr className="section-divider" style={{ borderColor: '#97E500' }} />
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-12 text-center">
            {['Live Ducks', 'Dead Ducks', 'Live Shots', 'Shots Fired'].map((item, i) => (
              <div className="d-flex stats" key={i}>
                <div className="col-6 text-left">
                  <p style={{ color: '#97E500' }}>{item}:</p>
                </div>
                <div className="col-6 text-right">
                  <p style={{ color: '#97E500' }}>
                    {i === 0 ? liveDucks :
                      i === 1 ? deadDucks :
                      i === 2 ? liveShots :
                      shotsFired}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>      

      <div className="container-fluid pt-4">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="d-flex text-center">
              <div className="col-6 text-left">
                <h2 className="section-title" style={{ color: '#3BC3FD' }}>Hodlers</h2>
              </div>
              <div className="col-6 text-right">
                <h2 className="section-title" style={{ color: '#3BC3FD' }}>Ducks</h2>
              </div>
            </div>
            <hr className="section-divider" style={{ borderColor: '#3BC3FD' }} />
          </div>
        </div>
      </div>

      <div className="container-fluid pb-4">
        <div className="row justify-content-center">
          <div className="col-12 text-center">
            {topHolders.map((holder, i) => (
              <div className="d-flex stats" key={i}>
                <div className="col-6 text-left">
                  <p style={{ color: '#3BC3FD' }}>{i + 1}. {obfuscateAddress(holder.address)}</p>
                </div>
                <div className="col-6 text-right">
                  <p style={{ color: '#3BC3FD' }}>{holder.count}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <button className="button-transparent" onClick={goToFaq}>↓</button>
    </div>
  );
}

export default App;
